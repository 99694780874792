import { ApplicationController } from "utils/application-controller"

export default class extends ApplicationController {
	static targets = ["search", "list"]

	connect() {
		console.log("connecy")
		let self = this
		this.searchTarget.addEventListener("keyup", (event) => {
			if (event.isComposing || event.keyCode === 229) {
				return
			}
			self.filterConversations()
		})
	}

	filterConversations() {
		let filter = this.searchTarget.value.toLowerCase()
		const items = this.listTarget.querySelectorAll("a[class=panel-block]")
		items.forEach(function(item) {
			let currentName = item.dataset.name.toLowerCase()
			if (currentName.indexOf(filter) > -1) {
				item.style.display = "flex"
			} else {
				item.style.display = "none"
			}
			// console.log(item.dataset.name)
		})
	}
}
