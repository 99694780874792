import { ApplicationController } from "utils/application-controller"

import { is_empty } from "utils/helpers"

import createChannel from "cable/cable"

export default class extends ApplicationController {
	static targets = ["messages"]

	connect() {
		this.scrollToLastMessage()
		let conversationController = this
		// console.log(this.data.get("id"))
		this.commentsChannel = createChannel(
			{
				channel: "ConversationChannel",
				conversation_slug: conversationController.data.get("id")
			},
			{
				connected() {
					console.log("connected")
				},
				disconnected: function() {
					console.log("disconnected!")
				},
				received(data) {
					console.log(data)
					let html = new DOMParser().parseFromString(data["html"], "text/html")
					const commentHTML = html.body.firstChild
					conversationController.appendMessage(commentHTML)
				}
			}
		)
		this.formHook()
	}

	formHook() {
		let form = document.getElementById("new_text_message")
		let self = this
		form.addEventListener("submit", e => {
			e.preventDefault()
			self.sendMessage()
		})
	}

	sendMessage() {
		let messageField = document.getElementById("text_message_content")
		let message = messageField.value
		if (is_empty(message)) {
			return
		}

		let slug = this.data.get("id")
		messageField.value = ""
		this.commentsChannel.perform("send_message", {
			content: message,
			conversation_slug: slug
		})
	}

	appendMessage(message) {
		this.messagesTarget.append(message)
		this.scrollToLastMessage()
	}

	scrollToLastMessage() {
		this.conversationWrapper.scrollTop = this.conversationWrapper.scrollHeight
	}

	get conversationWrapper() {
		return document.querySelector(".chat-conversation")
	}
}
