import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_blue.css";

export default class extends Controller {
	static targets = ["startDate", "startTime", "endDate", "endTime"];

	connect() {
		this.setupStartDatePicker();
		this.setupStartTimePicker();
		this.setupEndDatePicker();
		this.setupEndTimePicker();
	}

	setupStartDatePicker() {
		flatpickr(this.startDateTarget, {
			enableTime: false,
			disableMobile: true,
			dateFormat: "d/m/Y",
			minDate: new Date().fp_incr(1),
			onChange: (selectedDates, dateStr) => {
				this.startTimeTarget._flatpickr.open();
			},
		});
	}

	setupStartTimePicker() {
		flatpickr(this.startTimeTarget, {
			disableMobile: true,
			enableTime: true,
			noCalendar: true,
			dateFormat: "H:i",
			time_24hr: true,
			minuteIncrement: 1,
			defaultHour: 12,
			defaultMinute: 0,
			onChange: (selectedDates, timeStr) => {
				this.endDateTarget._flatpickr.open();
			},
		});
	}

	setupEndDatePicker() {
		flatpickr(this.endDateTarget, {
			disableMobile: true,
			enableTime: false,
			dateFormat: "d/m/Y",
			minDate: new Date().fp_incr(1),
			onChange: (selectedDates, dateStr) => {
				this.endTimeTarget._flatpickr.open();
			},
		});
	}

	setupEndTimePicker() {
		flatpickr(this.endTimeTarget, {
			disableMobile: true,
			enableTime: true,
			noCalendar: true,
			dateFormat: "H:i",
			time_24hr: true,
			minuteIncrement: 1,
			defaultHour: 14,
			defaultMinute: 0,
		});
	}
}