import { Controller } from "stimulus"
import Flickity from "flickity"
import "flickity/css/flickity.css"
import imagesloaded from "flickity-imagesloaded"

export default class extends Controller {
	initialize() {
		var carsList = document.querySelector(".cars-list")
		let initialIndexCars = window.innerWidth > 1024 ? 2 : 0;
		let initialIndexServices = window.innerWidth > 1024 ? 1 : (window.innerWidth > 748 ? 1 : 0);
		let initialIndexCities = window.innerWidth > 1024 ? 1 : (window.innerWidth > 748 ? 1 : 0);
		let initialIndexBrands = window.innerWidth > 748 ? 4 : 1;

		this.carsListflkty = new Flickity(carsList, {
			pageDots: false,
			selectedAttraction: 0.01,
			friction: 0.15,
			contain: true,
			imagesLoaded: true,
			prevNextButtons: false,
			cellAlign: 'center',
			groupCells: false,
			initialIndex: initialIndexCars
		})

		var slider = document.querySelector(".agencies")

		let sliderflkty = new Flickity(slider, {
			pageDots: false,
			prevNextButtons: true,
			imagesLoaded: true,
			lazyLoad: 2,
			autoPlay: false,
			cellAlign: 'center',
			initialIndex: initialIndexCities,
			contain: true,
			arrowShape: {
				x0: 10,
				x1: 60, y1: 50,
				x2: 70, y2: 40,
				x3: 30
			}
		});

		var servicesList = document.querySelector(".services")

		let serviceListflkty = new Flickity(servicesList, {
			pageDots: false,
			prevNextButtons: true,
			contain: true,
			initialIndex: initialIndexServices,
			wrapAround: false,
			imagesLoaded: true,
			cellAlign: 'center',
			arrowShape: {
				x0: 10,
				x1: 60, y1: 50,
				x2: 70, y2: 40,
				x3: 30
			}
		})

		var reviewsList = document.querySelector(".reviews")

		let reviewListflkty = new Flickity(reviewsList, {
			pageDots: false,
			prevNextButtons: false,
			wrapAround: false,
			imagesLoaded: true,
			cellAlign: 'center',
			arrowShape: {
				x0: 10,
				x1: 60, y1: 50,
				x2: 70, y2: 40,
				x3: 30
			}
		})

		var brandListflkty = document.querySelector(".brand-carousel")

		let brandflkty = new Flickity(brandListflkty, {
			contain: true,
			initialIndex: initialIndexBrands,
			pageDots: false,
			prevNextButtons: true,
			imagesLoaded: true,
			lazyLoad: 2,
			draggable: true,
			autoPlay: false,
			wrapAround: false,
			arrowShape: {
				x0: 10,
				x1: 60, y1: 50,
				x2: 70, y2: 40,
				x3: 30
			}
		});
	}
}