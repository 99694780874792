import {
	ApplicationController
} from "utils/application-controller"

export default class extends ApplicationController {
	connect() {
		this.bindModals()
	}

	onAjaxSuccess(event) {
		let [data, status, xhr] = event.detail
		this.modalHolder.innerHTML = xhr.response
		let target = event.target.dataset.target
		this.openModal(target)
	}

	showLightbox(event) {
		let original = event.target.dataset.sourceUrl
		const markup = `
        <div id="modal-image" class="modal modal-fx-3dSlit" data-controller="modals">
        <div class="modal-background"></div>
        <div class="modal-content is-huge is-image">
        <img src="${original}" alt="image">
        </div>
        <button class="modal-close is-large" aria-label="close"></button>
        </div>
        `
		this.modalHolder.innerHTML = markup
		var target = event.target.dataset.target
		this.openModal(target)
	}

	bindModals() {
		if (this.modalButtons.length > 0) {
			let self = this
			this.modalButtons.forEach(function ($el) {
				$el.addEventListener("click", function () {
					var target = $el.dataset.target
					self.openModal(target)
				})
			})
		}
		this.bindCloses()
	}

	bindCloses() {
		let self = this
		if (this.modalCloses.length > 0) {
			this.modalCloses.forEach(function ($el) {
				$el.addEventListener("click", function () {
					self.closeModals()
				})
			})
		}

		document.addEventListener("keydown", function (event) {
			var e = event || window.event
			if (e.keyCode === 27) {
				self.closeModals()
			}
		})
	}

	closeModals() {
		this.rootEl.classList.remove("is-clipped")
		this.modals.forEach(function ($el) {
			$el.classList.remove("is-active")
		})
	}

	get modals() {
		return this.getAll(".modal")
	}

	get modalButtons() {
		return this.getAll(".modal-button")
	}

	get modalCloses() {
		return this.getAll(
			".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
		)
	}
}