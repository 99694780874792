import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		this.setupTabsHandler()
	}

	setupTabsHandler() {
		let self = this
		// hook all tab titles clic event
		document.querySelectorAll(".sides li a").forEach(function(navEl) {
			navEl.onclick = function() {
				self.reset()
				this.parentNode.classList.toggle("active")
			}
		})
	}

	reset() {
		// reset side buttons
		var navEls = document.querySelectorAll(".sides li")
		navEls.forEach(function(navEl) {
			navEl.classList.remove("active")
		})
	}
}
