import { Controller } from "stimulus"
import Rails from "rails-ujs"
import init_maps_api from "utils/google_maps_api"

export default class extends Controller {
	static targets = ["map"]

	initialize() {
		document.addEventListener("maps_api:ready", this.on_maps_ready, {
			once: true,
		})
		init_maps_api()
	}

	on_maps_ready = () => {
		this.maps_ready = true
		this.initMap()
	}

	initMap() {
		let latLng = new google.maps.LatLng(43.659722, 7.2043575)
		this.map = new google.maps.Map(this.mapTarget, {
			zoom: 13,
			center: latLng,
			disableDefaultUI: true,
			zoomControl: true,
		})
		google.maps.event.trigger(this.map, "resize")
		this.configureMap()
	}

	configureMap() {
		let map = this.map
		let infoWindow = new google.maps.InfoWindow({
			pixelOffset: new google.maps.Size(0, 30),
		})

		// This will apply some styling to the infowindow
		infoWindow.addListener("domready", function() {
			// Reference to the DIV that wraps the content of infowindow
			var iwOuter = document.querySelector(".gm-style-iw")
			// this is the div that holds the infowindow background/shadow/tooltip
			iwOuter.style.padding = 0
		})

		// on map click close infoWindow
		google.maps.event.addListener(map, "click", function() {
			infoWindow.close()
		})

		this.loadMarkers(infoWindow)
		this.resizeMap()
	}

	setMarkers(markers, infoWindow) {
		let map = this.map
		let markerIcon =
			"https://s3-eu-west-1.amazonaws.com/static.movocars.rent/icons/car_marker.png"
		var bounds = new google.maps.LatLngBounds()

		markers.forEach((place, i) => {
			if (place.latitude == null && place.longitude == null) {
				return
			}
			var latLng = new google.maps.LatLng(place.latitude, place.longitude)
			bounds.extend(latLng) //add the position to our bounds holder
			// Create a marker for each submission
			var marker = new google.maps.Marker({
				position: latLng,
				map: map,
				icon: markerIcon,
				title: place.name,
				id: place.plate,
			})

			// On marker click, load submission content
			marker.addListener("click", function() {
				const content =
					'<div id="content">' +
					`<h1 class="title is-6">${marker.title}</h1>` +
					"<p>.</p>" +
					"</div>"
				infoWindow.setContent(content)
				infoWindow.open(map, marker)
			})
		})
		//fit all markers
		map.fitBounds(bounds)
	}

	resizeMap() {
		google.maps.event.trigger(this.map, "resize")
	}

	loadMarkers(infoWindow) {
		let url = this.mapTarget.dataset.markers
		let self = this
		Rails.ajax({
			url,
			type: "GET",
			success: function(markers) {
				if (markers.length > 0) {
					self.setMarkers(markers, infoWindow)
				}
			},
		})
	}
}
