import {
    Controller
} from "stimulus"
import Rails from "rails-ujs"

export class ApplicationController extends Controller {

    // Ajax
    railsUpdate(url, field, value) {
        return new Promise((resolve, reject) => {
            const data = new FormData()
            data.append(field, value)
            Rails.ajax({
                url,
                type: "PUT",
                dataType: 'json',
                data,
                success: data => {
                    resolve(data)
                },
                error: (_jqXHR, _textStatus, errorThrown) => {
                    reject(errorThrown)
                }
            });
        });
    }

    getControllerByIdentifier(identifier) {
        return this.application.controllers.find(controller => {
            return controller.context.identifier === identifier
        });
    }


    // Modals
    openModal(target) {
        if (target === undefined) {
            target = "modal-ter";
        }
        let targetModal = document.getElementById(target)
        if (!targetModal) return
        this.rootEl.classList.add("is-clipped")
        targetModal.classList.add("is-active")
    }

    get modalHolder() {
        return document.querySelector(".modal-holder")
    }

    get rootEl() {
        return document.documentElement
    }

    getAll(selector) {
        return Array.prototype.slice.call(document.querySelectorAll(selector), 0)
    }
}