import {
	Controller
} from "stimulus"

import "whatwg-fetch"
import Dropzone from "dropzone"
Dropzone.autoDiscover = false

export default class extends Controller {
	initialize() {
		let uploadZone = document.getElementById("uploadZone")
		this.setupDropZone(uploadZone)
	}

	setupDropZone(fileInput) {
		var fieldset = document.getElementsByTagName("fieldset")[0]
		var file_count = fileInput.dataset.mediaCount
		const field_name = fileInput.dataset.fieldName
		const accepted = fileInput.dataset.acceptedFiles
		const maxFiles = fileInput.dataset.maxFiles
		// @ts-ignore
		var myDropzone = new Dropzone(fileInput, {
			url: "/",
			autoProcessQueue: false,
			addRemoveLinks: true,
			paramName: "file",
			clickable: fileInput,
			acceptedFiles: accepted,
			previewsContainer: fileInput,
			maxFiles: maxFiles
		})

		myDropzone.on("addedfile", function (file) {
			fieldset.disabled = true
			let extension = file.name.match(/(\.\w+)?$/)[0] // set extension
			let date = Date.now() // prevent caching
			fetch(
					`/pictures/presign?type=${
					file.type
				}&extension=${extension}&_=${date}&filename=${file.name}`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json"
						}
					}
				)
				.then(function (response) {
					return response.json()
				})
				.then(function (json) {
					file.additionalData = json.fields
					myDropzone.options.url = json.url
					// trigger file processing for the current file
					myDropzone.processFile(file)
				})
				.catch(function (ex) {
					console.log("parsing failed", ex)
					fieldset.disabled = false
				})
		})

		myDropzone.on("sending", function (file, xhr, formData) {
			// Add the additional signed data
			for (var field in file.additionalData) {
				formData.append(field, file.additionalData[field])
			}
		})

		myDropzone.on("success", function (file) {
			var image = {
				id: file.additionalData.key.match(/cache\/(.+)/)[1], // we have to remove the cache prefix part
				storage: "cache",
				metadata: {
					size: file.size,
					filename: file.name.match(/[^\/\\]*$/)[0], // IE returns full path
					mime_type: file.type
				}
			}
			//for each successful upload append to form
			var fileField = document.createElement("input")
			fileField.setAttribute("type", "hidden")
			var attrName = field_name
			if (!maxFiles) {
				attrName += "[" + file_count + "]"
			}
			fileField.setAttribute("name", attrName + "[file]")
			fileField.setAttribute("value", JSON.stringify(image))
			fileField.className = file.lastModified
			fileInput.parentNode.appendChild(fileField)
			file_count++
		})

		// enable the form when done
		myDropzone.on("queuecomplete", function (file) {
			fieldset.disabled = false
		})

		myDropzone.on("removedfile", function (file) {
			let element = document.querySelector(`[class="${file.lastModified}"]`)
			element.parentNode.removeChild(element)
		})
	}
}