export default class {
	constructor(parent, coords) {
		this.parent = parent
		this.left = coords.left
		this.top = coords.top
	}

	add() {
		var tag = document.createElement("div")
		tag.style.left = `${this.left}%`
		tag.style.top = `${this.top}%`
		tag.classList.add("hotspot")
		tag.innerHTML = "<span></span>"
		tag.dataset.controller = "modals"
		tag.dataset.remote = "true"
		this.parent.appendChild(tag)
		tag.onclick = function (event) {
			console.log("clic tag")
		}
	}
}