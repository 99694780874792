import { Controller } from "stimulus"
import { Calendar } from "@fullcalendar/core"
import interaction from "@fullcalendar/interaction"
import resourceTimeline from "@fullcalendar/resource-timeline"
import enLocale from "@fullcalendar/core/locales/en-gb"
import frLocale from "@fullcalendar/core/locales/fr"

import "@fullcalendar/core/main.min.css"
import "@fullcalendar/timeline/main.min.css"
import "@fullcalendar/resource-timeline/main.min.css"

export default class extends Controller {
	connect() {
		var calendarEl = document.getElementById("calendar")
		let self = this
		let calendar = new Calendar(calendarEl, {
			plugins: [interaction, resourceTimeline],
			schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
			defaultView: "resourceTimelineMonth",
			locales: [enLocale, frLocale],
			aspectRatio: 1.5,
			firstDay: 1,
			resourceAreaWidth: "20%",
			slotWidth: 30,
			timeZone: "Europe/Paris",
			header: {
				left: "",
				center: "prev,title,next",
				right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
			},
			resourceLabelText: "Cars",
			resources: {
				url: "/admin/planning/cars.json",
				extraParams: {
					agency: self.form.agency.value,
					car_category: self.form.car_category.value,
					car_brand: self.form.car_brand.value,
				},
			},
			events: "/admin/planning/rentals.json",
			resourceRender: function(renderInfo) {
				// console.log(renderInfo.resource.extendedProps.html)
				renderInfo.el.innerHTML = renderInfo.resource.extendedProps.html
			},
			eventClick: function(info) {
				//console.log(info.event)
			},
		})

		calendar.render()
	}

	get form() {
		return document.forms.filters
	}
}
