import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"]

  display() {
    if (this.formTarget.style.display === 'block') {
      this.formTarget.style.display = 'none';
    } else {
      this.formTarget.style.display = 'block';
    }
  }
}