import { Controller } from "stimulus"

import init_maps_api from "utils/google_maps_api"

export default class extends Controller {
	initialize() {
		document.addEventListener("maps_api:ready", this.on_maps_ready, {
			once: true,
		})
		init_maps_api()
	}

	on_maps_ready = () => {
		this.maps_ready = true
		console.log("on_maps_ready")
		this.setupInput()
	}

	setupInput() {
		var inputs = document.querySelectorAll(".location-autocomplete")
		var options = {
			types: [],
			componentRestrictions: {
				country: ["fr", "mc", "es", "it", "de", "ch"],
			},
		}
		let self = this
		inputs.forEach(function(input) {
			let gPlace = new google.maps.places.Autocomplete(input, options)
			input.addEventListener("keydown", (event) => {
				if (event.keyCode === 13) {
					event.preventDefault()
					return false
				}
			})

			gPlace.target = input.dataset.target
			if (input.dataset.form) {
				gPlace.form = input.dataset.form
			}

			google.maps.event.addListener(gPlace, "place_changed", function() {
				let target = this.target
				self.clearLocation(input)

				let place = gPlace.getPlace()
				let location = self.locationFromPlace(place)
				for (var key in location) {
					var locField = document.createElement("input")
					locField.setAttribute("type", "hidden")
					locField.setAttribute("name", target + "[" + key + "]")
					locField.setAttribute("value", location[key])
					locField.classList.add("hidden_loc")
					input.parentNode.appendChild(locField)
				}
				let form = this.form
				if (form) {
					self.submitForm(form)
				}
			})
		})
	}

	locationFromPlace(place) {
		var location = {
			address: place.formatted_address,
			latitude: place.geometry.location.lat(),
			longitude: place.geometry.location.lng(),
		}

		for (var comp in place.address_components) {
			for (var type in place.address_components[comp].types) {
				if (place.address_components[comp].types[type] == "locality")
					location.city = place.address_components[comp].long_name
				if (
					!location.city &&
					place.address_components[comp].types[type] ==
						"administrative_area_level_2"
				)
					location.city = place.address_components[comp].long_name
				if (place.address_components[comp].types[type] == "country")
					location.country = place.address_components[comp].long_name
			}
		}

		return location
	}

	submitForm(form) {
		console.log(form)
		let target = document.forms[form]
		target.submit()
	}

	// Remove all location fields
	clearLocation(input) {
		let oldFields = input.parentNode.querySelectorAll(".hidden_loc")
		;[].forEach.call(oldFields, function(e) {
			e.parentNode.removeChild(e)
		})
	}
}
