import {
    Controller
} from "stimulus"

import {
    is_empty
} from "utils/helpers"

export default class extends Controller {

    static targets = ["submit"]

    initialize() {
        this.setupForm()
    }

    setupForm() {
        let self = this
        var stripe = Stripe(this.data.get("key"), {
            locale: 'fr'
        });
        var elements = stripe.elements()

        // Set up Stripe.js and Elements to use in checkout form
        var style = {
            base: {
                color: "#32325d",
            }
        };

        var card = elements.create("card", {
            style: style
        });

        card.mount("#card-element")

        card.addEventListener('change', function (event) {
            if (event.error) {
                self.showError(event.error.message)
            } else {
                self.showError(null)
            }
        });

        this.submitTarget.addEventListener("click", function (evt) {
            evt.preventDefault()
            // Initiate payment
            self.submitPayment(stripe, card)
        });
    }

    submitPayment(stripe, card) {
        let self = this
        const client_secret = this.data.get("intent")
        const billing_details = this.data.get("billing")
        this.changeLoadingState(true)
        stripe.confirmCardPayment(client_secret, {
            payment_method: {
                card: card,
                billing_details: {
                    name: billing_details
                }
            },
            setup_future_usage: 'off_session'
        }).then(function (result) {
            if (result.error) {
                self.changeLoadingState(false)
                // Show error to your customer
                console.log(result.error.message)
                self.showError(result.error.message)
            } else {
                console.log(result.paymentIntent)
                if (result.paymentIntent.status === 'succeeded') {
                    self.processPayment(result.paymentIntent)
                }
            }
        });
    }

    processPayment(paymentIntent) {
        document.forms.rental_payment.payment_stripe_intent.value = paymentIntent.id
        document.forms.rental_payment.submit()
    }

    // Show a spinner on payment submission
    changeLoadingState(isLoading) {
        if (isLoading) {
            this.submitTarget.classList.add("is-loading")
            this.submitTarget.disabled = true
        } else {
            this.submitTarget.classList.remove("is-loading")
            this.submitTarget.disabled = false
        }
    }

    showError(errorMessage) {
        var errorMsg = document.getElementById('card-errors')
        if (!errorMessage || is_empty(errorMessage)) {
            errorMsg.textContent = ""
            return
        }

        errorMsg.textContent = errorMessage
        setTimeout(function () {
            errorMsg.textContent = ""
        }, 6000)
    }
}