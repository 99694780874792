import { ApplicationController } from "utils/application-controller"
import Rails from "rails-ujs"

export default class extends ApplicationController {
	initialize() {
		this.hookQtyButtons()
		this.hookOptions()
		this.hookSwitch()
		this.hookSubmitButton()
	}

	hookSubmitButton() {
		let self = this
		document.querySelectorAll("#submitConfig").forEach((btn) => {
			btn.addEventListener("click", (e) => {
				e.preventDefault()
				btn.classList.add("is-loading")
				btn.disabled = true
				if (self.form) {
					self.form.action = self.form.dataset.target
					self.refreshForm()
				}
			})
		})
	}

	hookQtyButtons() {
		let self = this
		document.querySelectorAll(".count-input-dec").forEach((dec) => {
			dec.addEventListener("click", (e) => {
				const countInput = e.target.nextElementSibling
				let currentValue = parseInt(countInput.value)
				if (currentValue === 1) return
				self.updateQuantity(countInput, currentValue - 1)
			})
		})

		document.querySelectorAll(".count-input-inc").forEach((inc) => {
			inc.addEventListener("click", (e) => {
				const countInput = e.target.previousElementSibling
				let currentValue = parseInt(countInput.value)
				if (currentValue >= 2) return
				self.updateQuantity(countInput, currentValue + 1)
			})
		})
	}

	hookOptions() {
		let self = this;
		document.querySelectorAll(".tags.options .tag").forEach((option) => {
			option.addEventListener("click", (e) => {
				console.log("Option clicked:", option.textContent.trim());
				const optionsContainer = option.closest(".tags.options");
				optionsContainer.querySelectorAll(".tag.selected").forEach((prev) => {
					prev.classList.remove("selected");
				});
				option.classList.add("selected");
				const hiddenInput = optionsContainer.querySelector("input[type='hidden']");
				hiddenInput.value = option.textContent.trim();
				console.log("Hidden input value:", hiddenInput.value);
				self.refreshForm();
			});
		});
	}

	hookSwitch() {
		let self = this
		document.querySelectorAll(".addon-switch input").forEach((seatSwitch) => {
			self.toggleAddonQuantity(seatSwitch)

			if (seatSwitch.classList.contains("quantifiable")) {
				self.toggleSeatQtyControl(seatSwitch)
			}

			seatSwitch.addEventListener("change", (event) => {
				self.toggleAddonQuantity(seatSwitch)
				self.refreshForm()
			})
		})

		document.querySelectorAll('.kilometers input[type="range"]').forEach((kmSwitch) => {
			self.toggleKmPackage(kmSwitch)
			kmSwitch.addEventListener("change", (event) => {
				self.toggleKmPackage(event.target)
				self.refreshForm()
			})
		})
	}

	toggleKmPackage(kmSwitch) {
		const kmInput = document.getElementById("rental_km_package")
		kmInput.value = kmSwitch.value > 0 ? "daily_200" : "daily_100"
	}

	toggleAddonQuantity(addonSwitch) {
		const qtyControl = addonSwitch.closest('.kilometers').querySelector("[name$='[quantity]']")
		qtyControl.value = addonSwitch.checked ? 1 : 0

		if (addonSwitch.classList.contains("quantifiable")) {
			this.toggleSeatQtyControl(addonSwitch)
		}
	}

	toggleSeatQtyControl(seatSwitch) {
		const qtyControl = seatSwitch.parentElement.nextElementSibling
		if (seatSwitch.checked) {
			qtyControl.classList.remove("hide-control")
		} else {
			qtyControl.classList.add("hide-control")
		}
	}

	updateQuantity(countInput, currentValue) {
		if (countInput.readOnly) {
			return
		}
		countInput.value = currentValue
		const qtyControl = countInput.closest('.kilometers').querySelector("[name$='[quantity]']")
		qtyControl.value = currentValue
		this.refreshForm()
	}

	refreshForm() {
		Rails.fire(this.form, "submit")
	}

	get form() {
		return document.forms.rental_config
	}
}