import { Controller } from "stimulus"

export default class extends Controller {
	static keycodes = { space: 32, enter: 13 }

	connect() {
		this.setupTabsHandler()
	}

	setupTabsHandler() {
		let self = this
		// hook all rows to clic event
		document.querySelectorAll("table .js-table-row").forEach(function(navEl) {
			navEl.onclick = self.visitDataUrl
		})
	}

	visitDataUrl = function(event) {
		if (
			event.type == "click" ||
			event.keyCode == this.keycodes.space ||
			event.keyCode == this.keycodes.enter
		) {
			if (
				event.target.href ||
				// allow clics from modals
				["a", "i"].includes(event.target.tagName.toLowerCase())
			) {
				return
			}
			let dataUrl = event.target.parentNode.closest("tr").dataset.url
			let selection = window.getSelection().toString()
			if (selection.length === 0 && dataUrl) {
				window.location = dataUrl
			}
		}
	}
}
