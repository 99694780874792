import {
    Controller
} from "stimulus"

export default class extends Controller {
    static targets = ["picker", "fileInput"]

    initialize() {
        let self = this
        this.pickerTarget.addEventListener("click", () => {
            self.fileInputTarget.click();
        });

        self.fileInputTarget.addEventListener("change", (event) => {
            const file = event.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            self.pickerTarget.style.backgroundImage = `url(${imageUrl})`;
            self.pickerTarget.classList.add("has-image");
        });
    }
}