import {
    Controller
} from "stimulus"
import Rails from "rails-ujs"
export default class extends Controller {

    brandChanged(event) {
        let element = this.element
        let index = element.selectedIndex
        let brandValue = element.options[index].value
        document.getElementById('car_model_id').innerHTML = '<option value="">Select a brand first</option>'
        if (!brandValue) {
            return
        }
        let url = `/admin/car_brands/${brandValue}/models.js`
        Rails.ajax({
            url,
            dataType: 'html',
            type: "GET"
        })
    }

    companyChanged(event) {
        let element = this.element
        let index = element.selectedIndex
        let value = element.options[index].value
        document.getElementById('car_agency').innerHTML = '<option value="">Select a company first</option>'
        if (!value) {
            return
        }
        let url = `/admin/companies/${value}/agencies.js`
        Rails.ajax({
            url,
            dataType: 'html',
            type: "GET"
        })
    }

}