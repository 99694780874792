import { Controller } from "stimulus";
import Flickity from "flickity";

export default class extends Controller {
    initialize() {
        const slider = document.querySelector(".brand-carousel");
        let initialIndexBrands = window.innerWidth > 748 ? 4 : 1;

        let sliderflkty = new Flickity(slider, {
            contain: true,
            initialIndex: initialIndexBrands,
            pageDots: false,
            prevNextButtons: true,
            imagesLoaded: true,
            lazyLoad: 2,
            draggable: true,
            autoPlay: false,
            wrapAround: false,
            arrowShape: {
                x0: 10,
                x1: 60, y1: 50,
                x2: 70, y2: 40,
                x3: 30
            }
        });
    }
}