import {
	Controller
} from "stimulus"

export default class extends Controller {
	static targets = ["content", "titles"]

	connect() {
		this.setupTabsHandler()
	}

	setupTabsHandler() {
		let self = this
		// hook all tab titles clic event
		this.titlesTarget.querySelectorAll(".tabs li").forEach(function (navEl) {
			navEl.onclick = function () {
				self.toggleTab(this.dataset.target)
				this.classList.add("is-active")
			}
		})
	}

	toggleTab(targetId) {
		// toggle titles
		var navEls = this.titlesTarget.querySelectorAll(".tabs li")
		navEls.forEach(function (navEl) {
			navEl.classList.remove("is-active")
		})

		// toggle content
		var tabs = this.contentTarget.querySelectorAll(".tab-pane")
		tabs.forEach(function (tab) {
			tab.classList.toggle("is-active", tab.id == targetId)
		})
	}
}