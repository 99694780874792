import {
    Controller
} from "stimulus"
import Rails from "rails-ujs"

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {

    static targets = ["range", "start", "end"]

    connect() {
        this.setupDatePicker()
    }

    setupDatePicker() {
        let self = this
        let startDate = new Date(this.startTarget.value) || null
        let endDate = new Date(this.endTarget.value) || null

        let dates = [startDate, endDate].filter((obj) => obj)
        console.log(dates)
        this.startPicker = flatpickr(this.rangeTarget, {
            enableTime: false,
            dateFormat: "d/m/Y",
            mode: 'range',
            defaultDate: dates,
            minDate: new Date(), //today
            onChange: function (selectedDates, dateStr, instance) {
                let value = dateStr.split(" to ");
                self.startTarget.value = value[0]
                self.endTarget.value = value[1]
            }
        });
    }


    sliderChanged(event) {
        let url = this.data.get("id")
        Rails.ajax({
            url,
            dataType: 'html',
            type: "PATCH"
        });
    }

    get form() {
        return document.querySelector('.maintenance-form')
    }

}