import { Controller } from "stimulus";
import Flickity from "flickity";
import imagesLoaded from "flickity-imagesloaded";
import asNavFor from "flickity-as-nav-for";

export default class extends Controller {
	initialize() {
		const steps = document.querySelector(".steps");
		const slider = document.querySelector(".carousel");
		const progressBar = document.querySelector("#progressBar");
		const progressLabel = document.querySelector("#progressLabel");
		const totalSteps = 8;

		let sliderflkty = new Flickity(slider, {
			pageDots: false,
			prevNextButtons: false,
			imagesLoaded: true,
			lazyLoad: 2,
			autoPlay: true,
			disable: true
		});

		const stepPercentage = 100 / sliderflkty.cells.length;

		progressBar.value = 12.5;
		progressLabel.textContent = `1/${totalSteps}`;

		sliderflkty.on("change", () => {
			const progressValue = stepPercentage * (sliderflkty.selectedIndex + 1);
			progressBar.value = progressValue;

			progressLabel.textContent = `${sliderflkty.selectedIndex + 1}/${totalSteps}`;
		});

		// let stepsflkty = new Flickity(steps, {
		// 	asNavFor: ".carousel",
		// 	pageDots: false,
		// 	prevNextButtons: false,
		// });
	}
}