import {
	ApplicationController
} from "utils/application-controller"

export default class extends ApplicationController {
	addonChanged(event) {
		if (this.element.checked) {
			this.insertAddon(this.element)
		} else {
			this.removeAddon(this.element)
		}
	}

	insertAddon(element) {
		let addonId = element.value
		let nextIndex = this.checkboxes.length
		var idField = document.createElement("input")
		idField.setAttribute("type", "hidden")
		idField.setAttribute(
			"name",
			"agency[addon_prices_attributes][" + nextIndex + "][addon_id]"
		)
		idField.setAttribute("value", addonId)
		var wrapper = document.createElement("div")
		wrapper.classList.add("field", addonId)
		wrapper.appendChild(idField)
		var priceField = document.createElement("input")
		priceField.setAttribute(
			"name",
			"agency[addon_prices_attributes][" + nextIndex + "][price]"
		)
		priceField.setAttribute("placeholder", "Price")
		priceField.setAttribute("type", "number")
		priceField.setAttribute("step", "0.01")
		priceField.setAttribute("required", "required")
		priceField.classList.add("input")
		wrapper.appendChild(priceField)
		element.parentNode.appendChild(wrapper)
	}

	removeAddon(element) {
		let oldFields = document.querySelectorAll(`div[class~="${element.value}"]`);
		[].forEach.call(oldFields, function (e) {
			e.parentNode.removeChild(e)
		})
	}

	get checkboxes() {
		return this.getAll("input.switch[type='checkbox']:checked")
	}
}