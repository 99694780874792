import {
    Controller
} from "stimulus"

export default class extends Controller {

    connect() {
        this.setupMobileMenu()
        this.setupSidebar()
    }

    setupMobileMenu() {
        const burger = document.querySelector('.burger')
        burger.addEventListener('click', function (e) {
            e.preventDefault()
            const node = e.currentTarget
            if (node) {
                // Get the target from the "data-target" attribute
                let target = node.dataset.target
                if (target) {
                    const targetNode = document.getElementById(target)
                    if (targetNode) {
                        // Toggle the "is-active" class on both the burger button and the target
                        node.classList.toggle('is-active')
                        targetNode.classList.toggle('is-active')
                    }
                }
            }
        })
    }

    setupSidebar() {
        // Sidebar links
        const menuItems = document.querySelectorAll('#sideMenu .menu-list li.has-dropdown')
        if (menuItems.length > 0) {
            menuItems.forEach(function (item) {
                let itemLink = item.querySelector('a')
                itemLink.addEventListener('click', function (event) {
                    item.classList.toggle('is-active')
                })
            })
        }
    }
}