import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["trigger"]

	connect() {
		this.setupClicHandler()
	}

	setupClicHandler() {
		this.triggerTarget.onclick = function() {
			this.classList.toggle("is-active")
		}
	}
}
