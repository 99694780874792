// Remove all elements by className
export function clearElements(className) {
	let oldFields = document.querySelectorAll(`[class="${className}"]`);
	[].forEach.call(oldFields, function (e) {
		e.parentNode.removeChild(e)
	})
}

export function is_empty(text) {
	return (text.toString().length === 0 || !text.toString().trim());
};