import {
    Controller
} from "stimulus"

export default class extends Controller {
    static targets = ["picker", "fileInput"]

    initialize() {
        let self = this
        document.querySelectorAll(".file-drop-zone").forEach(dropZone => {
            dropZone.addEventListener("dragover", self.dragOverHandler);

            dropZone.addEventListener("dragenter", self.dragEnterHandler);

            dropZone.addEventListener("dragleave", self.dragLeaveHandler);

            dropZone.addEventListener("drop", self.dropHandler);

            dropZone.addEventListener("click", function () {
                self.clickHandler(dropZone)
            })
        })

        document.querySelectorAll("input[type=file]").forEach(input => {
            input.addEventListener("change", function () {
                self.changeHandler(input)
            })
        })
    }

    dragOverHandler = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    dragEnterHandler = event => {
        event.stopPropagation();
        event.preventDefault();
        event.target.classList.add("is-active");
    }

    dragLeaveHandler = event => {
        event.stopPropagation();
        event.preventDefault();
        event.target.classList.remove("is-active");
    }

    dropHandler = event => {
        event.preventDefault();
        event.stopPropagation();
        event.target.classList.remove("is-active");
        const uploader = event.target.parentElement;
        const placeholder = uploader.querySelector('.file-placeholder');
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        this.uploadHandler(placeholder, file);
    }

    clickHandler(target) {
        console.log(target.previousElementSibling)
        target.previousElementSibling.click();
    }

    uploadHandler = (placeholder, file) => {
        placeholder.classList.add("has-image");
        const imageUrl = URL.createObjectURL(file);
        placeholder.style.backgroundImage = `url(${imageUrl})`;
    }

    changeHandler = (target) => {
        const uploader = target.parentElement;
        const placeholder = uploader.querySelector('.file-placeholder');
        const file = target.files[0];
        this.uploadHandler(placeholder, file);
    }

    deleteHandler = (target) => {
        const placeholder = target.parentElement;
        const uploader = placeholder.parentElement;
        placeholder.style.backgroundImage = "";
        placeholder.classList.remove("has-image");
        uploader.querySelector("input").value = "";
    }
}