import {
	Controller
} from "stimulus"
import Rails from "rails-ujs"
import moment from "moment"
import Calendar from "tui-calendar"
import "tui-calendar/dist/tui-calendar.css"

// If you use the default popups, use this.
import "tui-date-picker/dist/tui-date-picker.css"
import "tui-time-picker/dist/tui-time-picker.css"

export default class extends Controller {
	static targets = ["menu", "range", "toggles"]

	connect() {
		this.calendar = new Calendar("#calendar", {
			usageStatistics: false,
			disableClick: true,
			timezones: [{
				timezoneOffset: -120,
			}],
			defaultView: "week",
			month: {
				startDayOfWeek: 1
			}
		})

		this.getAvailability()
		this.getUnavailability()
		this.setNavigationEvents()
		this.renderRangeText()
		this.hookToggles()

		// event handlers
		this.calendar.on({
			clickMore: function (e) {
				console.log("clickMore", e)
			},
			clickSchedule: function (e) {
				var schedule = e.schedule
				console.log("clickSchedule", schedule)
				if (schedule.raw) {
					window.open(schedule.raw, '_blank')
				}
			},
			clickDayname: function (date) {
				console.log("clickDayname", date)
			}
		})
	}

	createSchedule(items) {
		this.calendar.createSchedules(items)
	}

	getAvailability() {
		let self = this
		let url = this.data.get("url") + "/availability"
		Rails.ajax({
			url,
			dataType: "json",
			type: "GET",
			success: function (response) {
				self.createSchedule(response)
			}
		})
	}

	getUnavailability() {
		let self = this
		let url = this.data.get("url") + "/unavailability"
		Rails.ajax({
			url,
			dataType: "json",
			type: "GET",
			success: function (response) {
				self.createSchedule(response)
			}
		})
	}

	/// Handle next/prev/today actions
	setNavigationEvents() {
		let self = this
		this.menuTarget.addEventListener("click", e => {
			var action = e.target.dataset.action

			switch (action) {
				case "move-prev":
					self.calendar.prev()
					break
				case "move-next":
					self.calendar.next()
					break
				case "move-today":
					self.calendar.today()
					break
				default:
					return
			}

			self.renderRangeText()
		})
	}

	/// Handle switching calendar layout Month/Week
	hookToggles() {
		let self = this
		this.togglesTarget.addEventListener("click", e => {
			var action = e.target.dataset.action
			var viewName = this.calendar.getViewName()
			if (viewName === action) {
				return
			}

			switch (action) {
				case "month":
					self.calendar.changeView("month", true)
					self.renderRangeText()
					break
				case "week":
					self.calendar.changeView("week", true)
					self.renderRangeText()
					break
				default:
					return
			}
			self.resetToggles()
		})
	}

	/// Reset calendar layout is-selected state
	resetToggles() {
		const items = this.togglesTarget.querySelectorAll(".button")
		items.forEach(function (item) {
			item.classList.toggle("is-selected")
		})
	}

	/// Render the range displayed
	renderRangeText() {
		var renderRange = this.rangeTarget
		var viewName = this.calendar.getViewName()
		var html = []
		if (viewName === "month") {
			let date = this.calendar.getDate().getTime()
			html.push(moment(date).format("MMMM YYYY"))
		} else {
			html.push(
				moment(this.calendar.getDateRangeStart().getTime()).format("DD-MM-YYYY")
			)
			html.push(" ~ ")
			html.push(
				moment(this.calendar.getDateRangeEnd().getTime()).format(" DD-MM")
			)
		}
		renderRange.innerHTML = html.join("")
	}
}