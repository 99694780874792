import {
    Controller
} from "stimulus"

export default class extends Controller {
    initialize() {
        this.setupThumbs()
    }

    setupThumbs() {
        let self = this
        // hook all thumbs clic event
        document.querySelectorAll(".thumbnails .thumbnail").forEach(function (navEl) {
            navEl.onclick = function () {
                self.togglePreview(navEl.dataset.target, navEl.dataset.url)
            }
        })
    }

    togglePreview(targetId, url) {
        // toggle titles
        var navEls = document.querySelectorAll(`img[class~="${targetId}"]`)
        navEls.forEach(function (navEl) {
            navEl.src = url
        })
    }
}