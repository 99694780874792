import { Controller } from "stimulus"

import bulmaIconpicker from "bulma-extensions/bulma-iconpicker/dist/js/bulma-iconpicker"

export default class extends Controller {
	initialize() {
		let iconPickerOptions = {
			iconSets: [
				{
					name: "Brands", // Name displayed on tab
					css:
						"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css", // CSS url
					prefix: "fa-", // CSS rules prefix to identify icons
					displayPrefix: "fab fa-icon",
				},
				{
					name: "Solid", // Name displayed on tab
					css:
						"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css", // CSS url
					prefix: "fa-", // CSS rules prefix to identify icons
					displayPrefix: "fas fa-icon",
				},
			],
		}
		let iconPicker = new bulmaIconpicker("#iconPicker", iconPickerOptions)
		console.log(iconPicker)
		iconPicker.on("click", function (icon) {
			console.log(icon)
		})
	}
}