import { ApplicationController } from "utils/application-controller"
import Rails from "rails-ujs"
import Hotspot from "models/hotspot"

export default class extends ApplicationController {
	static targets = ["canvas"]

	connect() {
		this.setupClicEvent()
	}

	/// Add clic handler on car image
	setupClicEvent() {
		let self = this
		const image = this.canvasTarget.getElementsByTagName("img")[0]
		image.onclick = function(event) {
			self.clickcoord(event)
		}
	}

	clickcoord(event) {
		// console.log(event)
		const image = this.canvasTarget.getElementsByTagName("img")[0]
		const coords = this.calculateClickCordinates(event, image)
		// let hotspot = new Hotspot(this.canvasTarget, coords)
		// hotspot.add()
		let url =
			this.data.get("url") +
			`&position[left]=${coords.left}&position[top]=${coords.top}`
		let self = this
		Rails.ajax({
			url,
			dataType: "html",
			type: "GET",
			success: function(response) {
				self.modalHolder.innerHTML = response.activeElement.innerHTML
				self.openModal()
			}
		})
	}

	getOffset(el) {
		if (el.getBoundingClientRect) {
			return el.getBoundingClientRect()
		} else {
			var x = 0,
				y = 0
			do {
				x += el.offsetLeft - el.scrollLeft
				y += el.offsetTop - el.scrollTop
			} while ((el = el.offsetParent))

			return {
				left: x,
				top: y
			}
		}
	}

	/// compute the clic position relative to the document
	calculateClickCordinates(event, img) {
		const rect = this.getOffset(img)
		console.log(rect)
		console.log(event.clientX)
		let x = ((event.clientX - rect.left) / img.width) * 100
		let y = Math.max(((event.clientY - rect.top) / img.height) * 100, 0)
		return {
			left: Math.floor(x),
			top: Math.floor(y)
		}
	}
}
